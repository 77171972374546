<template>
    <div class="stats" style="height: 100%">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>数据统计</el-breadcrumb-item>
                    <el-breadcrumb-item>疾病时间统计</el-breadcrumb-item>
                </el-breadcrumb>
                <head-center></head-center>
            </nav>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <form action="#" class="searchbox">
                            <el-date-picker v-model="datepicker" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                            </el-date-picker>
                            <!--<input type="text" class="text search-input" placeholder="输入关键词搜索...">-->
                            <el-button type="primary" size="small" @click="search()">搜索</el-button>
                        </form>
                    </div>
                </div>
                <el-tabs v-model="activeName" class="stats-tab" style="height: calc(100% - 48px)">
                    <el-tab-pane label="中医疾病时间统计" name="ZDisease">
                        <el-table :data="ZDiseaseTableData" stripe style="width: 100%" height="calc(100% - 40px)" :header-cell-style="{background:'#F5F7FC'}">
                            <el-table-column prop="entityName" label="疾病名称" width="250">
                            </el-table-column>
                            <el-table-column prop="entityAlias" label="疾病别名" width="350" align="center">
                            </el-table-column>
                            <el-table-column prop="entityCategory" label="疾病分类" align="center">
                            </el-table-column>
                            <el-table-column prop="stayTime" label="浏览时长" width="120" align="center">
                                <template slot-scope="data">{{ FormatSecond(data.row.stayTime) }}</template>
                            </el-table-column>
                            <el-table-column prop="lastTime" label="最后浏览时间" width="180" align="center">
                                <template slot-scope="scope"> {{ scope.row.lastTime | timefilters }} </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="100" align="center">
                                <template slot-scope="scope">
                                    <span class="primary optionBtn" @click="ToZDetail(scope.row)">详情
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="100" :current-page="PageIndex"
                            :total="ZDiseaseTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="西医疾病时间统计" name="XDisease">
                        <el-table :data="XDiseaseTableData" stripe style="width: 100%" height="calc(100% - 40px)" :header-cell-style="{background:'#F5F7FC'}">
                            <el-table-column prop="entityName" label="疾病名称" width="250">
                            </el-table-column>
                            <el-table-column prop="entityAlias" label="疾病别名" width="350" align="center">
                            </el-table-column>
                            <el-table-column prop="entityCategory" label="疾病分类" align="center">
                            </el-table-column>
                            <el-table-column prop="stayTime" label="浏览时长" width="120" align="center">
                                <template slot-scope="data">{{ FormatSecond(data.row.stayTime) }}</template>
                            </el-table-column>
                            <el-table-column prop="lastTime" label="最后浏览时间" width="180" align="center">
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="100" align="center">
                                <template slot-scope="scope">
                                    <span class="primary optionBtn" @click="ToXDetail(scope.row)">详情
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="100" :current-page="PageIndex"
                            :total="XDiseaseTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    </el-tab-pane>
                </el-tabs>

            </div>
        </div>
        <!--    详情    -->
        <el-dialog title="时间统计详情" :visible.sync="dialogTableVisible">
            <el-table :data="DetailData" height="500" :header-cell-style="{background:'#F5F7FC'}">
                <el-table-column prop="userName" label="员工">
                    <template slot-scope="data">
                        <p>{{ data.row.learnName }}</p>
                        <p>{{ data.row.phone }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="organizationName" label="所属单位"></el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="userTitleName" label="职称"></el-table-column>
                <el-table-column prop="userPostName" label="职位"></el-table-column>
                <el-table-column prop="stayTime" label="浏览时长">
                    <template slot-scope="data">{{ FormatSecond(data.row.stayTime) }}</template>
                </el-table-column>
                <el-table-column prop="lastTime" label="最后浏览时间"></el-table-column>
            </el-table>
            <el-pagination @current-change="ChangeDetailPage" :page-size="100" :current-page="PageIndex"
                :total="DetailTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
import { STATS } from '../../../components/STATS/STATS';
import { StrToShotDate, FormatSecond } from '../../../components/common/Date';
export default {
    name: "DiseaseStats",
    data() {
        var stats = new STATS(this.TokenClient, this.Services.Healthy);
        return {
            StatsDomin: stats,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            FormatSecond: FormatSecond,
            ZDiseaseTableData: [],
            XDiseaseTableData: [],
            datepicker: '',
            activeName: 'ZDisease',
            statrTime: '',
            endTime: '',
            PageIndex: 1,
            PageTotal: 1,
            ZDiseaseTotal: 1,
            XDiseaseTotal: 1,
            ZhDiseaseList: [],
            XDiseaseList: [],
            dialogTableVisible: false,
            DetailData: [],
            DetailTotal: 0,
            type: 'disease',
            entityId: 0,
        }
    },
    mounted() {
        this.GetZDiseaseList();
        this.GetXDiseaseList();
    },
    methods: {
        GetZDiseaseList() {
            var _this = this;
            _this.StatsDomin.ZDiseaseList(_this.startTime, _this.endTime, _this.PageIndex,
                function (data) {
                    _this.ZDiseaseTableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.ZDiseaseTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err);
                }
            )

        },
        GetXDiseaseList() {
            var _this = this;
            _this.StatsDomin.XDiseaseList(_this.startTime, _this.endTime, _this.PageIndex,
                function (data) {
                    _this.XDiseaseTableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.XDiseaseTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err);
                }
            )

        },
        search() {
            this.PageIndex = 1;
            this.startTime = this.datepicker ? this.datepicker[0] + ' 00:00:00' : null;
            this.endTime = this.datepicker ? this.datepicker[1] + ' 23:59:59' : null;
            if (this.activeName == 'ZDisease') {
                this.GetZDiseaseList();
            }
            else if (this.activeName == 'XDisease') {
                this.GetXDiseaseList();
            }
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            if (this.activeName == 'ZDisease') {
                this.GetZDiseaseList();
            }
            else if (this.activeName == 'XDisease') {
                this.GetXDiseaseList();
            }
        },
        ChangeDetailPage(pageIndex) {
            this.PageIndex = pageIndex;
            if (this.activeName == 'ZDisease') {
                this.ToZDetail();
            }
            else if (this.activeName == 'XDisease') {
                this.ToXDetail();
            }
        },
        ToZDetail(item) {
            this.dialogTableVisible = true;
            var _this = this;
            _this.StatsDomin.EntityBrowseTopDetail(_this.type, item.entityKey, _this.startTime, _this.endTime, _this.PageIndex,
                function (data) {
                    _this.DetailData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.DetailTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err)
                }
            )
        },
        ToXDetail(item) {
            this.dialogTableVisible = true;
            var _this = this;
            _this.StatsDomin.EntityBrowseTopDetail(_this.type, item.entityKey, _this.startTime, _this.endTime, _this.PageIndex,
                function (data) {
                    _this.DetailData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.DetailTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err)
                }
            )
        },
    },
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

/*content*/


.table-top {
    margin-bottom: 15px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 300px;
    padding: 5px 40px 5px 15px;
    margin-right: 10px;
    border-radius: 5px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}
</style>
<style>
.stats-tab .el-tabs__content {
    height: calc(100% - 40px);
}

.stats-tab .el-tab-pane {
    height: 100%;
}

.stats .el-dialog {
    width: 950px;
}

.stats .el-dialog .el-table {
    height: 500px;
}
</style>
